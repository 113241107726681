<template>
  <div> 

    <!-- modal -->
    <b-modal
      id="modal-prevent-closing"
      ref="my-modal"
      title="Submit Your Name"
      ok-title="Submit"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >
        <b-form-group
          :state="nameState"
          label="Name"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <b-form-input
            id="name-input"
            v-model="name"
            :state="nameState"
            required
          />
        </b-form-group>
      </form>
    </b-modal>

    <b-card no-body class="mb-0">
      <!-- title="Gestion des Ressources Humaines" -->
      <b-tabs>
        <b-tab active>
          <template #title>
            <feather-icon icon="ListIcon" />
            <span>Liste des Assertion</span>
          </template>

          <b-card-text>
            <!-- Table Container Card -->
            <b-card no-body class="mb-0">
              <div class="m-2">
                <!-- Table Top -->
                <b-row>
                  <!-- Per Page -->
                  <b-col
                    cols="12"
                    md="4"
                    class="
                      d-flex
                      align-items-center
                      justify-content-start
                      mb-1 mb-md-0
                      pull-left
                    "
                  >
                    <label>Afficher</label>
                    <v-select
                      v-model="perPage"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="perPageOptions"
                      :clearable="false"
                      class="per-page-selector d-inline-block mx-50"
                    />
                    <label>Elements par page</label>
                  </b-col>

                  <!-- Search -->
                  <b-col cols="12" md="8">
                    <div
                      class="
                        d-flex
                        align-items-center
                        justify-content-end
                        pull-right
                      "
                    >

                      <b-form-input
                        v-model="searchQuery"
                        class="d-inline-block mr-1"
                        placeholder="Rechercher..."
                      />


                      <b-button
                        variant="primary"
                        :to="{ name: 'assertion-add-new' }"
                      >
                        <!-- @click="isAddNewDataSidebarActive = true" -->
                        <span class="text-nowrap">Nouvelle Assertion</span>
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </div>

              <b-table
                id="tableFicheLawyer"
                ref="refDataListTable"
                class="position-relative"
                :items="fetchLawyers"
                responsive
                :fields="tableColumns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="Aucun resultat trouvé"
                :sort-desc.sync="isSortDirDesc"
              >
                <template #cell(direction)="data">
                  <div class="text-nowrap">
                    <feather-icon
                      :icon="resolveLawyerIcon(data.item.direction)"
                      size="18"
                      class="mr-50"
                      :class="`text-${resolveLawyerVariant(
                        data.item.direction
                      )}`"
                    />
                    <span class="align-text-top text-capitalize">{{
                      data.item.direction
                    }}</span>
                  </div>
                </template>

                <!-- Column: Status -->
                <template #cell(status)="data">
                  <b-badge
                    pill
                    :variant="`light-${resolveDataStatusVariant(
                      data.item.status
                    )}`"
                    class="text-capitalize"
                  >
                    {{ data.item.status }}
                  </b-badge>
                </template>
 
                <!-- Column: Actions -->
                <template #cell(actions)="data">
                  <div class="text-nowrap"> 
                    <b-dropdown
                      variant="link"
                      no-caret
                      :right="$store.state.appConfig.isRTL"
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </template>
                      <b-dropdown-item
                        :to="{
                          name: 'assertion-view',
                          params: { id: data.item.id },
                        }"
                      >
                        <feather-icon icon="FileTextIcon" />
                        <span class="align-middle ml-50">Détails</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        :to="{
                          name: 'assertion-edit',
                          params: { id: data.item.id },
                        }"
                      >
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">Modifier</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        @click="onDeleteQuestion(data.item)"
                        @refetch-data="refetchData"
                      >
                        <feather-icon icon="TrashIcon" />
                        <span class="align-middle ml-50">Supprimer</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </template>
              </b-table>
              <div class="mx-2 mb-2">
                <b-row>
                  <b-col
                    cols="12"
                    sm="6"
                    class="
                      d-flex
                      align-items-center
                      justify-content-center justify-content-sm-start
                    "
                  >
                    <span class="text-muted"
                      >Affiché {{ dataMeta.from }} à {{ dataMeta.to }} sur
                      {{ dataMeta.of }} Enregistements</span
                    >
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                    cols="12"
                    sm="6"
                    class="
                      d-flex
                      align-items-center
                      justify-content-center justify-content-sm-end
                    "
                  >
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalDatas"
                      :per-page="perPage"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </b-card-text>
        </b-tab> 
     
      </b-tabs> 
    </b-card>

    <!-- Filters -->
    <!-- <list-data-table-filters
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
    /> -->
  </div>
</template>

<script>
import {
  BCard,
    BRow,
    BCol,
    BImg,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BBadge,
    BTooltip,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTabs,
    BCardText,
    BTab,
    // table
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
} from "bootstrap-vue";

import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useModuleList from "./useList";
import storeModule from "./storeModule";
// import ListDataTable from "./ListDataTable.vue";
import ListDataTableFilters from "./ListDataTableFilters.vue";

// sweet alert
import Ripple from "vue-ripple-directive";
import { codeBasic } from "../../extensions/sweet-alert/code";
//
// notifications
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { codeIcon } from "../../extensions/toastification/code";

import { $themeConfig } from "@themeConfig";

export default {
  components: {
    ListDataTableFilters,
    // ListDataTable, //

    BCard,
    BRow,
    BCol,
    BImg,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BBadge,
    BTooltip,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BTabs,
    BCardText,
    BTab,
    // table
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot, 
  },
  data() {
    return {
      codeIcon,
      assertionData: {},
      monthOptions: [],
      assertionOptions: [],
      ficheLawyer: [],
      assertionDataLoaded: false,
      name: '',
      nameState: null,
      submittedNames: [],

    };
  },
  directives: {
    Ripple,
  },
  props: {},
  async mounted() {
    // year
    /* 
    // load assertions list
    await axios
      .get(`assertion`)
      .then((response) => {
        // default action
        var data = response.data.assertions;
        var i = 0;
        for (i = 0; i < data.length; i++) {
          this.assertionOptions.push({
            label:
              data[i].firstname +
              " " +
              data[i].lastname +
              " " +
              data[i].surname,
            value: data[i].sku_assertion,
          });
        }
      })
      .catch((error) => {
        //
        console.log(error);
      }); */
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.name = ''
      this.nameState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }

      var id = 1;

      // Push the name to submitted names
      this.submittedNames.push(this.name)
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['my-modal'].toggle('#toggle-btn');
      })
    },
    printLawyerFile() {
      window.print();
    },
    onDeleteQuestion(assertion) {
      this.$swal({
        title: "Action Irreversible",
        text: "Voulez-vous vraiment supprimer ?",
        icon: "question",
        timer: 10000,
        showCancelButton: true,
        confirmButtonText: "Oui [Confirmer Suppression]",
        cancelButtonText: "Annuler",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-outline-primary ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          // yes
          this.onDelete(assertion);
        } else if (result.dismiss === "cancel") {
          // stay here
        }
      });
    },
    async loadLawyerData() {
      // load assertions list
      this.assertionDataLoaded = false;
      await axios
        .get(
          $themeConfig.app.api_endpoint +
            `get_assertion_file/${$themeConfig.app.api_endpoint_access_token}`,
          {
            params: this.assertionData,
          }
        )
        .then((response) => {
          // default action
          this.ficheLawyer = JSON.parse(JSON.stringify(response.data.data));
          console.log(this.ficheLawyer.length);
          console.log(this.ficheLawyer);
          // var i = 0;
          // for (i = 0; i < data.length; i++) {}
          this.assertionDataLoaded = true;
        })
        .catch((error) => {
          //
          this.assertionDataLoaded = true;
          console.log(error);
        });
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = "app-assertion";
    // test
    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME))
      store.registerModule(APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME))
        store.unregisterModule(APP_STORE_MODULE_NAME);
    });

    const onDelete = (assertion) => {
      store
        .dispatch(APP_STORE_MODULE_NAME + "/deleteModule", assertion.id)
        .then(() => {
          // show confirmation notification
          refetchData();
        });
    };

    const isAddNewDataSidebarActive = ref(false);

    const typeOptions = [
      { label: "Matière Première", value: "first-way" },
      { label: "Produit Semi-Fini", value: "half-finished" },
      { label: "Produit Fini", value: "finished" },
      { label: "Produit Dérivé", value: "derived" },
      { label: "Consommables", value: "consummable" },
      { label: "Service", value: "service" },
      { label: "Autres", value: "other" },
    ];

    const statusOptions = [
      { label: "En Activité", value: "active" },
      { label: "Inactif", value: "inactive" },
      { label: "Non-Spécifié", value: "pending" },
    ];

    const resolveLawyerVariant = (direction) => {
      if (direction === "in") return "primary";
      if (direction === "out") return "warning";
      return "primary";
    };

    const resolveLawyerIcon = (direction) => {
      if (direction === "in") return "ArrowDownIcon";
      if (direction === "out") return "ArrowUpIcon";
      return "UserIcon";
    };

    const {
      fetchLawyers,
      tableColumns,
      perPage,
      currentPage,
      totalDatas,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      refetchData,
      // UI
      resolveDataTypeVariant,
      resolveDataTypeIcon,
      resolveDataStatusVariant,

      // Extra Filters
      typeFilter,
      statusFilter,
    } = useModuleList();

    return {
      // Sidebar
      isAddNewDataSidebarActive,

      fetchLawyers,
      tableColumns,
      perPage,
      currentPage,
      totalDatas,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      refetchData,
      onDelete,

      // Filter
      avatarText,

      // UI
      resolveLawyerIcon,
      resolveLawyerVariant,
      resolveDataTypeVariant,
      resolveDataTypeIcon,
      resolveDataStatusVariant,

      typeOptions,
      statusOptions,

      // Extra Filters
      typeFilter,
      statusFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
